/**
 * In dit bestand worden de site-specifieke styles gedefinieerd voor de agegate.
 */
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700|Open+Sans:400,700,300,300italic,700italic);
.agegate.agegate-back {
  background: url('https://www.hertogjan.nl/admin_assets/content/content_visuals/323-header_01.jpg');
  background-size: cover;
  background-position: center center;
}
.agegate.agegate-back:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.65) 100%);
}
.agegate.agegate-noscript {
  background: #000 !important;
  color: #fff !important;
  padding: 40px !important;
  font-size: 14px !important;
}
.agegate .agegate-content-spacer-top {
  height: 90px;
}
.agegate .agegate-content {
  background: #000;
}
.agegate .agegate-logo {
  background: url('https://www.hertogjan.nl/site/assets/img/logo-hertogjan-centered.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 120px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}
.agegate .agegate-header {
  font-family: 'Oswald';
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #fff;
  margin-bottom: 10px;
}
.agegate .agegate-subheader {
  font-family: 'Oswald';
  font-weight: lighter;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 0;
  color: #fff;
  margin-bottom: 20px;
}
.agegate .agegate-input {
  font-family: Oswald;
  text-transform: uppercase;
}
.agegate .agegate-button {
  color: #fff;
  text-transform: uppercase;
  font-family: Oswald;
  background: rgba(255, 255, 255, 0.2);
  transition: background 300ms;
  font-weight: normal;
  text-align: center;
  padding: 10px 30px;
  font-size: 22px;
  border: 0;
}
.agegate .agegate-button,
.agegate .agegate-button:hover,
.agegate .agegate-button:focus,
.agegate .agegate-button:active,
.agegate .agegate-button:active:focus {
  color: #fff;
  box-shadow: none;
  outline: 0;
}
.agegate .agegate-button:hover,
.agegate .agegate-button:active {
  background: rgba(255, 255, 255, 0.3);
}
.agegate .agegate-error {
  font-family: Oswald;
  font-weight: lighter;
  background: rgba(255, 0, 0, 0.1);
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}
.agegate .agegate-checkbox-label {
  font-family: Oswald;
  text-transform: uppercase;
  font-weight: lighter;
}
.agegate .agegate-cookie-info-link,
.agegate .agegate-remember-warning,
.agegate .agegate-cookie-info,
.agegate .agegate-bottom {
  font-family: Oswald;
  font-weight: lighter;
  font-size: 16px;
}
.agegate .agegate-cookie {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}
.agegate .agegate-bottom {
  font-size: 15px;
}
.agegate .agegate-bottom,
.agegate .agegate-bottom a {
  color: #aaa;
}
