
@import '_fonts';

* {
    //box-sizing: border-box;
}

body {
    font-family: 'Open Sans';
    color: #000;
    font-size: 16px;
}

a {
    color: #bbb;
}


h2, .h2 {
    font-family: 'Oswald';
    font-size: 25px;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 4px;
    color: #fff;
}

h3, .h3 {
    font-family: 'Oswald';
    font-weight: lighter;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 0;
    color: #fff;
}