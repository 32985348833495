
/**
 * In dit bestand worden de site-specifieke styles gedefinieerd voor de agegate.
 */

@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700|Open+Sans:400,700,300,300italic,700italic);

@import (reference) 'style';


.agegate {
    &.agegate-back {
        background: url('https://www.hertogjan.nl/admin_assets/content/content_visuals/323-header_01.jpg');
        background-size: cover;
        background-position: center center;

        &:after {
            position: absolute;
            content: "";
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.65) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.65) 100%);
        }
    }

    &.agegate-noscript {
        background: #000 !important;
        color: #fff !important;
        padding: 40px !important;
        font-size: 14px !important;
    }

    .agegate-content-spacer-top {
        // Make space for the logo negative top margin
        height: 90px;
    }

    .agegate-content {
        background: #000;
    }

    .agegate-logo {
        background: url('https://www.hertogjan.nl/site/assets/img/logo-hertogjan-centered.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 120px;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -100px;
    }


    // Text / form elements

    .agegate-header {
        .h2;
        margin-bottom: 10px;
    }

    .agegate-subheader {
        .h3;
        margin-bottom: 20px;
    }

    .agegate-input {
        font-family: Oswald;
        text-transform: uppercase;
    }

    .agegate-button {
        color: #fff;
        text-transform: uppercase;
        font-family: Oswald;
        background: rgba(255, 255, 255, 0.2);
        transition: background 300ms;
        font-weight: normal;
        text-align: center;
        padding: 10px 30px;
        font-size: 22px;
        border: 0;

        &, &:hover, &:focus, &:active, &:active:focus {
            color: #fff;
            box-shadow: none;
            outline: 0;
        }

        &:hover, &:active {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    .agegate-error {
        font-family: Oswald;
        font-weight: lighter;
        background: rgba(255, 0, 0, .1);
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }

    .agegate-checkbox-label {
        font-family: Oswald;
        text-transform: uppercase;
        font-weight: lighter;
    }

    .agegate-cookie-info-link, .agegate-remember-warning, .agegate-cookie-info, .agegate-bottom {
        font-family: Oswald;
        font-weight: lighter;
        font-size: 16px;
    }


    // Styling per block

    .agegate-cookie {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }

    .agegate-bottom {
        font-size: 15px;

        &, a {
            color: #aaa;
        }
    }

}
